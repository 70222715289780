<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card header="Afgerond" class="mt-4">
          <page-snippet name="api-success" />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

  export default {
    name: 'Finished',
    components: {
      'page-snippet': () => import('@/components/PageSnippet'),
    },
    data () {
      return {
        snippetName: null
      }
    },
    props: {
      release: Object,
      createdRelease: Object,
    },
    watch: {
    },
    methods: {

    },
    computed: {
    },
    mounted () {

    }
  }
</script>

<style lang="scss" scoped>

</style>